import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import logoLong from '../img/logo_long.svg';
import '../styles/SignUpIn.css';

export default class ForgotView extends Component {
  constructor(props){
    super(props);
    this.state = {
      userId: '',
      err: ''
    }
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e){
    this.setState({
      [e.target.name] : e.target.value
    })
  }

  handleSubmit(e){
      e.preventDefault();
      this.setState({
        err: 'This app is not yet set up!'
      })
  }

  render() {
    return (
      <div className="SignUpIn">
        <header className="SignUpIn-header">
          <img src={logoLong} className="ForgotSignUp-logo" alt="long-logo" />
          <br />
          <div class="forgot-title">
            <h4>forgot your password?</h4>
            <small><em>let's get that rest for you</em></small>
          </div>
          <div className="error-message">&nbsp;{this.state.err}&nbsp;</div>
          <form className="form" onSubmit={this.handleSubmit}>
            <div className="form-group">
              <input required
                type="email"
                name="userId"
                placeholder="email here"
                className="form-control"
                value={this.state.userId}
                onChange={this.handleChange} />
            </div>
            <div className="form-group">
              <input
                className="SignUpIn-submit form-control"
                type="submit"
                value="send email" />
            </div>
          </form>

          <div className="to-location-text">
            <Link to="/" className="text-link" id="forgot_login">&larr; login</Link>
          </div>

        </header>
      </div>
    );
  }
}
