import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import logoLong from '../img/logo_long.svg';
import '../styles/Navbar.css';

export default class Navbar extends Component {
  render() {
    return (
      <nav className="navbar navbar-dark bg-dark navbar-expand-lg">
        <Link to="/" id="brand" className="navbar-brand">
          <img src={logoLong} className="Navbar-logo" alt="long-logo" />
        </Link>
        <div className="collapse navbar-collapse">
          <ul className="navbar-nav mr-auto">
            <li className="navbar-item">
              <Link to="/" className="nav-link">dashboard</Link>
            </li>
            <li className="navbar-item">
              <Link to="/explore" className="nav-link">explore</Link>
            </li>
            <li className="navbar-item">
              <Link to="/account" className="nav-link">account</Link>
            </li>
          </ul>
        </div>
      </nav>

    );
  }
}
