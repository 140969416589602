import React, {Component} from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import SplashScreen from './views/SplashScreen';
import LoginView from './views/LoginView';
import ForgotView from './views/ForgotView';
import SignUpView from './views/SignUpView';
import SignUpAltIdView from './views/SignUpAltIdView';
import SignUpAccInfoView from './views/SignUpAccInfoView';
import SignUpDistrictsView from './views/SignUpDistrictsView';
import MainView from './views/MainView';
import 'bootstrap/dist/css/bootstrap.min.css';

const view = <SplashScreen />;
class App extends Component {
  constructor(){
    super();
    this.state = {
      loading: true,
      loggedin: false
    }
  }

  componentDidMount() {
    setTimeout(() => this.setState({ loading: false }), 3000);
  }

  //determines which view to set at any time.
  setView() {
    if (this.state.loading) {
      return (
          <SplashScreen />
      );
    } else {
      if (this.state.loggedin) {
        return (
            <Route path="/" exact component={MainView} />
        );
      } else {
        return (
          <div>
            <Route path="/" exact component={LoginView} />
            <Route path="/sign_up" exact component={SignUpView} />
            <Route path="/sign_up_alt_id" exact component={SignUpAltIdView} />
            <Route path="/sign_up_acc_info" exact component={SignUpAccInfoView} />
            <Route path="/sign_up_districts" exact component={SignUpDistrictsView} />
            <Route path="/forgot" exact component={ForgotView} />
          </div>
        );
      }
    }
  }

  render() {
    return (
      <Router>
          <div className="app">
            {this.setView()}
          </div>
      </Router>

    );
  }
}

export default App;
