import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import logoLong from '../img/logo_long.svg';
import '../styles/SignUpIn.css';

export default class SignUpAccInfoView extends Component {
  constructor(props){
    super(props);
    this.state = {
      firstName: '',
      lastName: '',
      birthdayMo: '',
      birthdayDay: '',
      birthdayYear: '',
      getTexts: true,
      voteReminders: true,
      months: [],
      days: [],
      years: [],
      err: ''
    }
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    var date = new Date();
    var n = date.getFullYear();
    var y = [(n-18)];
    var d = [];
    for(var i = 110; i > 0; i--){
      y.push((y[y.length - 1] - 1));
    };
    for(var i = 1; i < 32; i++) {
      d.push(i);
    }

    this.setState({
      months: ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEPT', 'OCT', 'NOV', 'DEC'],
      days: d,
      years: y
    })
  }

  handleChange(e){
    if (e.target.type == 'checkbox') {
      if(e.target.name == 'voteReminders') {
        this.setState({
          [e.target.name] : !this.state.voteReminders
        })
      } else {
        this.setState({
          [e.target.name] : !this.state.getTexts
        })
      }
    } else {
      this.setState({
        [e.target.name] : e.target.value
      })
    }
  }

  handleSubmit(e){
      e.preventDefault();
      this.setState({
        err: 'This app is not yet set up!'
      })
      //document.getElementById('to_sign_up_districts').click();
  }

  render() {
    return (
      <div className="SignUpIn">
        <header className="SignUp-header">
          <img src={logoLong} className="SignUp-logo" alt="long-logo" />
        </header>
        <div className="SignUp-container">
          <div class="signUp-title">
            <h4>fill us in</h4>
            <small><em>let's get some account info while you are here</em></small>
          </div>
          <div className="error-message">&nbsp;{this.state.err}&nbsp;</div>
          <form className="form" onSubmit={this.handleSubmit}>
            <div className="form-group">
              <input
                type="text"
                name="firstName"
                placeholder="First Name"
                className="form-control"
                value={this.state.firstName}
                onChange={this.handleChange} />
            </div>
            <div className="form-group">
              <input
                type="text"
                name="lastName"
                placeholder="Last Name"
                className="form-control"
                value={this.state.lastName}
                onChange={this.handleChange} />
            </div>
            <div className="form-group birthday-group">
              <div className="signUp-title">birthday</div>
              <select
                name="birthdayMo"
                className="form-control"
                value={this.state.birthdayMo}
                onChange={this.handleChange}>
                <option>Month</option>
                {this.state.months.map(  month => <option key={month} value={month}>{month}</option> )}
              </select>
              <select
                name="birthdayDay"
                className="form-control"
                value={this.state.birthdayDay}
                onChange={this.handleChange}>
                <option>Day</option>
                {this.state.days.map(  day => <option key={day} value={day}>{day}</option> )}
              </select>
              <select
                name="birthdayYear"
                className="form-control"
                value={this.state.birthdayYear}
                onChange={this.handleChange}>
                <option>Year</option>
                {this.state.years.map(  year => <option key={year} value={year}>{year}</option> )}
              </select>
            </div>
            <div className="form-group">
              <label className="form-check-label checkbox-text check-side">
              <input
                type="checkbox"
                name="voteReminders"
                className="form-check-input"
                checked={this.state.voteReminders}
                onChange={this.handleChange} /> vote reminders
              </label>
              <label className="form-check-label checkbox-text check-side">
              <input
                type="checkbox"
                name="getTexts"
                className="form-check-input"
                checked={this.state.getTexts}
                onChange={this.handleChange} /> accept texting
              </label>
            </div>
            <div className="form-group">
                <input
                  className="SignUpIn-submit form-control"
                  type="submit"
                  value="continue" />
                <Link
                  to="/sign_up_districts"
                  sytle={{ display: 'none' }}
                  id="to_sign_up_districts"></Link>
            </div>
          </form>

          <div className="to-location-text">
            <Link to="/sign_up_districts" className="text-link" id="forgot_login">skip &rarr;</Link>
          </div>
        </div>
      </div>
    );
  }
}
