import React, {Component} from 'react';
import Navbar from './Navbar';
import logo from '../img/logo.svg';

export default class MainView extends Component {
  constructor(props){
    super(props);
    this.state = {
    }
  }

  render() {
    return (
      <div>
        <Navbar />
      </div>
    );
  }
}
