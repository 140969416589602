import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import logoLong from '../img/logo_long.svg';
import '../styles/SignUpIn.css';

export default class SignUpView extends Component {
  constructor(props){
    super(props);
    this.state = {
      userId: '',
      err: '',
      wantUpdates: true,
      password: '',
      repassword: ''
    }
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e){
    if (e.target.type == 'checkbox'){
      this.setState({
        [e.target.name] : !this.state.wantUpdates
      })
    } else {
      this.setState({
        [e.target.name] : e.target.value
      })
    }
  }

  handleSubmit(e){
    e.preventDefault();
    if (this.state.password === this.state.repassword){
      document.getElementById('to_sign_up_alt_id').click();
    } else {
      this.setState({
        err: 'This app is not yet set up!'
      })
    }
  }

  render() {
    return (
      <div className="SignUpIn">
        <header className="SignUpIn-header">
          <img src={logoLong} className="ForgotSignUp-logo" alt="long-logo" />
          <br />
          <div className="error-message">&nbsp;{this.state.err}&nbsp;</div>
          <form className="form" onSubmit={this.handleSubmit}>
            <div className="form-group">
              <input required
                type="email"
                name="userId"
                placeholder="email"
                className="form-control"
                value={this.state.userId}
                onChange={this.handleChange} />
            </div>
            <div className="form-group">
              <label className="form-check-label checkbox-text">
              <input
                type="checkbox"
                name="wantUpdates"
                className="form-check-input"
                checked={this.state.wantUpdates}
                onChange={this.handleChange} /> get email updates
              </label>
            </div>
            <div className="form-group">
              <input required
                type="password"
                name="password"
                placeholder="password"
                className="form-control"
                value={this.state.password}
                onChange={this.handleChange} />
            </div>
            <div className="form-group">
              <input required
                type="password"
                name="repassword"
                placeholder="password again"
                className="form-control"
                value={this.state.repassword}
                onChange={this.handleChange} />
            </div>
            <div className="form-group">
                <input
                  className="SignUpIn-submit form-control"
                  type="submit"
                  value="sign up" />
                <Link
                  to="/sign_up_alt_id"
                  sytle={{ display: 'none' }}
                  id="to_sign_up_alt_id"></Link>
            </div>
          </form>

          <div className="to-location-text">
            <Link to="/" className="text-link" id="forgot_login">&larr; login</Link>
          </div>

        </header>
      </div>
    );
  }
}
