import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import logoLong from '../img/logo_long.svg';
import '../styles/SignUpIn.css';

export default class SignUpAltIdView extends Component {
  constructor(props){
    super(props);
    this.state = {
      altUserId: '',
      loginErr: '',
    }
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e){
    if (e.target.type == 'checkbox'){
      this.setState({
        [e.target.name] : !this.state.wantUpdates
      })
    } else {
      this.setState({
        [e.target.name] : e.target.value
      })
    }
  }

  handleSubmit(e){
    e.preventDefault();
    var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    var altUserId = this.state.altUserId.replace(/\D/g,'');
    if (this.state.altUserId.match(phoneno)) {
      document.getElementById('to_sign_up_acc_info').click();
    } else {
      this.setState({
        err: "Must be a 10 digit phone number, ex: 555-555-5555"
      })
    }
  }

  render() {
    return (
      <div className="SignUpIn">
        <header className="SignUp-header">
          <img src={logoLong} className="SignUp-logo" alt="long-logo" />
        </header>
        <div className="SignUp-container">
          <div className="signUp-title">
            <h4>login 2.0</h4>
            <small><em>login with your phone #</em></small>
          </div>
          <div className="error-message">&nbsp;{this.state.err}&nbsp;</div>
          <form className="form" onSubmit={this.handleSubmit}>
            <div className="form-group">
              <input required
                type="text"
                name="altUserId"
                placeholder="phone number"
                className="form-control"
                value={this.state.altUserId}
                onChange={this.handleChange} />
            </div>
            <div className="form-group">
                <input
                  className="SignUpIn-submit form-control"
                  type="submit"
                  value="continue" />
                <Link
                  to="/sign_up_acc_info"
                  sytle={{ display: 'none' }}
                  id="to_sign_up_acc_info"></Link>
            </div>
          </form>

          <div className="to-location-text">
            <Link to="/sign_up_acc_info" className="text-link" id="forgot_login">skip &rarr;</Link>
          </div>
        </div>
      </div>
    );
  }
}
