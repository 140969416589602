import React from 'react';
import logo from '../img/logo.svg';
import loading from '../img/loading.svg';
import '../styles/Splash.css';

function SplashScreen() {
  return (
    <div className="Splash">
      <header className="Splash-header">
        <img src={logo} className="Splash-logo" alt="logo" />
        <p id="slogan">make your mark</p>
        <img src={loading} className="Splash-loading" alt="loading" />
      </header>
    </div>
  );
}

export default SplashScreen;
