import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import logo from '../img/logo.svg';
import '../styles/SignUpIn.css';

export default class LoginView extends Component {
  constructor(props){
    super(props);
    this.state = {
      userId: '',
      password: '',
      err: ' '
    }
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e){
    this.setState({
      [e.target.name] : e.target.value
    })
  }

  handleSubmit(e){
      e.preventDefault();
      this.setState({
        err: 'This app is not yet set up!'
      })

  }

  render() {
    return (
      <div className="SignUpIn">
        <header className="SignUpIn-header">
          <img src={logo} className="Login-logo" alt="logo" />
          <br/>
          <div className="error-message">&nbsp;{this.state.err}&nbsp;</div>
          <form className="form" onSubmit={this.handleSubmit}>
            <div className="form-group">
              <input required
                type="text"
                name="userId"
                placeholder="user id"
                className="form-control"
                value={this.state.userId}
                onChange={this.handleChange} />
            </div>
            <div className="form-group">
              <input required
                type="password"
                name="password"
                placeholder="password"
                className="form-control"
                value={this.state.password}
                onChange={this.handleChange} />
            </div>
            <div className="form-group">
              <input
                className="SignUpIn-submit form-control"
                type="submit"
                value="login" />
            </div>
          </form>

          <div className="to-location-text">
            <Link to="/forgot" className="text-link" id="forgot_login">forgot login</Link><span className="text-link"> | </span><Link to="sign_up" className="text-link" id="sign_up">sign up</Link>
          </div>

        </header>
      </div>
    );
  }
}
